import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from '@capturum/ui/loader';
import { LoginConfig, LoginService as CapturumLoginService } from '@capturum/login';
import { ToastService } from '@capturum/ui/api';
import { UntypedFormBuilder } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class LoginService extends CapturumLoginService {
  constructor(
    protected readonly config: LoginConfig,
    protected readonly router: Router,
    protected readonly activatedRoute: ActivatedRoute,
    protected readonly toastService: ToastService,
    protected readonly translateService: TranslateService,
    protected readonly formBuilder: UntypedFormBuilder,
    private readonly loadingService: LoadingService,
  ) {
    super(config, router, activatedRoute, toastService, translateService, formBuilder);
  }

  public onLoginSuccess(result: any): void {
    this.loadingService.toggleLoading(true, 'Loading', './assets/images/loader.gif', 'metis-resolver-loader');

    super.onLoginSuccess(result);
  }
}
