import { Injectable } from '@angular/core';
import { AuthConfig, AuthService as BlueprintAuthService } from '@capturum/auth';
import { catchError, map, Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '@capturum/complete';
import { Admin } from '@core/interfaces/admin.interface';

interface AuthResult<T> {
  status: string;
  token: string;
  user: T;
  refresh_token?: string;
}

@Injectable({ providedIn: 'root' })
export class AuthService extends BlueprintAuthService<User> {
  constructor(config: AuthConfig, http: HttpClient) {
    super(config, http);
  }

  public loginUser(
    url: string,
    body: Record<string, any>,
    options: Record<string, any> = null,
  ): Observable<AuthResult<User>> {
    const customHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });

    return this.http
      .post<any>(
        `${this.config.baseUrl}/${url}`,
        { ...body, ...options },
        {
          headers: this.getHeaders(customHeaders),
        },
      )
      .pipe(
        map((result) => {
          if (result.status === 'ok') {
            if (result.user) {
              const user = result.user;

              localStorage.setItem('user', JSON.stringify(user));
            }

            localStorage.setItem('token', result.token);

            if (result.refresh_token) {
              localStorage.setItem('refresh_token', result.refresh_token);
            }

            this.handleSuccessfulLogin(result);
            this.setAuthenticationState(true);

            return result;
          }
        }),
        catchError((error) => {
          return throwError(() => {
            return error?.error?.message;
          });
        }),
      );
  }

  public loginAsAdministrator(body: Partial<Admin>): Observable<AuthResult<User>> {
    return this.loginUser('auth/admin-login', body);
  }

  public loginAsOperator(body: { pinCode: string }): Observable<AuthResult<User>> {
    return this.loginUser('auth/operator-login', body);
  }
}
